import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import MapsWave from "../../components/maps/maps-wave";
import PageLayout from "../../components/maps/layout";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = PageLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <MapsWave mdxType="MapsWave">
      <pre><code parentName="pre" {...{
          "className": "language-json"
        }}>{`{
  "center": [-38.7183, -62.2663],
  "zoom": 8
}
`}</code></pre>
      <h2>{`Res arsit alta elusaque dixit`}</h2>
      <p>{`Lorem markdownum seniles. Rediere iam quid laudat turba medias vires vocatur
pavet aptius, pondere patris, circuitu! Vias sumpsisse.`}</p>
      <ul>
        <li parentName="ul">{`Certamine at sidereum inmissusque magis tyranni ulla`}</li>
        <li parentName="ul">{`Poterat solitumque sunt`}</li>
        <li parentName="ul">{`Superbum genibusque mediis`}</li>
        <li parentName="ul">{`Pocula infans et in satis`}</li>
      </ul>
      <p>{`Vox calidumque exceptas etiamnum oculosque tendit `}<a parentName="p" {...{
          "href": "http://coepisseedidit.net/spectabere"
        }}>{`fratris relinquam
sinuatoque`}</a>{` pedibus; non. Perdidit iniquis
belua animoque carcere, per saltu non, mota rore. Venit `}<em parentName="p">{`iubet tenuissimus`}</em>{`,
ille posuisti iuppiter, iam quam tradere. Sibyllae trepidare aequore terrae.
Posceret autumnos `}<em parentName="p">{`licet surrexit`}</em>{` in late, fluxit, iaculo prius; volenti
venenum adulterium haec cervice ab duro erat.`}</p>
      <pre><code parentName="pre" {...{
          "className": "language-json"
        }}>{`{
  "center": [-38.7183, -62.2663],
  "zoom": 6
}
`}</code></pre>
      <h2>{`Non laborum cum`}</h2>
      <p>{`Exitium porrexit referam ceratis, `}<em parentName="p">{`dantem si super`}</em>{`, iuncta ad, de consueta
`}<strong parentName="p">{`mille dabis`}</strong>{`. Audaci illis prohibent visa.`}</p>
      <ol>
        <li parentName="ol">{`Esset feros tantum suos dedantur`}</li>
        <li parentName="ol">{`Nec posco sensit dira`}</li>
        <li parentName="ol">{`Per medii ubi Threicius Circen`}</li>
      </ol>
      <p>{`In sine nata semper nova Tegeaea, sed fassurae agam cum lapillos et iacens
memori domosque fere, nato. Statione potiar modo totiens novae obstipui in heros
quodcumque quoque gemellos Rhodopeius temone, non. Lotos audire sordida obliquo;
ira accipit in Aurora, Helles postquam. Infregit secundo coniuge iussa cur tamen
pro fretum quid saepibus, tibi.`}</p>
      <pre><code parentName="pre" {...{
          "className": "language-json"
        }}>{`{
  "center": [-34.6037, -58.3816],
  "zoom": 6
}
`}</code></pre>
      <h2>{`Tela centauri nobis perhorruit pugnat facundis claudit`}</h2>
      <p>{`Revellit latebras tamen vivo `}<strong parentName="p">{`vellera herbas`}</strong>{`. Ulixem huius acerbo
recondiderat verba acceptaque ater mansit tandem, ex? Fortuna caeca collectum
crudelis rogant increpuit, primum Ecce, fessamque
`}<a parentName="p" {...{
          "href": "http://ad-tu.org/quid.html"
        }}>{`illis`}</a>{` quae `}<strong parentName="p">{`fama`}</strong>{` modo longius dei `}<a parentName="p" {...{
          "href": "http://qua-ingrate.io/telum.php"
        }}>{`fugiunt
vindice`}</a>{`.`}</p>
      <p>{`Tertius passus ossibus inter, pervenerat armis ruricolae in sibi suffusus,
`}<strong parentName="p">{`se`}</strong>{` quod nec quod: dumosaque iungat color. Dextra dictis aures mirum,
rescindere petit, cavo molli pia suarum, tum cum gradu.`}</p>
      <p>{`Est fuga colore translata liquida pervenit Laomedonque prius volventem alarum
infelix corpus canum valeant. Colle `}<a parentName="p" {...{
          "href": "http://pater-nec.net/"
        }}>{`prensoque`}</a>{` cecidit
oscula. Artus senioribus manum quod, tamen caput circumdata nata fictos; ora
arguit durisque excutior.`}</p>
      <p>{`Sit eligendi dignissim et, sit eius ancillae voluptatibus te. Ea summo nostrum omnesque sed. Deserunt iracundia definiebas ei est.`}</p>
      <pre><code parentName="pre" {...{
          "className": "language-json"
        }}>{`{
  "center": [-34.6037, -58.3816],
  "zoom": 10
}
`}</code></pre>
      <h2>{`Caput haec iam extulerat transire unda vultus`}</h2>
      <p><strong parentName="p">{`Lorem markdownum`}</strong>{` quantus laeta `}<a parentName="p" {...{
          "href": "http://et-corpus.com/serpit"
        }}>{`et vincere`}</a>{`
castique tenuit; in erras: iacebas gramen, totiens Danaam: ad. `}<em parentName="p">{`Rescindere`}</em>{`
ripas, vestem, qua otia est, ferox avidamque, purgamina.`}</p>
      <p>{`Achilles priori Aethiopasque plura duabus Phorcidas, ventis `}<em parentName="p">{`conlapsaque quod`}</em>{`.
Tanto pectore rebus: atria quae semina corpus; igne recens ferrum violesque
quibus fugiuntque aequos, raptatur malignas tuas. Et maledicere placitas
`}<em parentName="p">{`Phinea`}</em>{` lacrimis ad petit ardescunt dumque excipiunt longis iuvenis tendentem
malo. Danaam mane, remissis vis nova `}<strong parentName="p">{`sinit`}</strong>{` parte, truces et sonat noctis!`}</p>
      <pre><code parentName="pre" {...{
          "className": "language-json"
        }}>{`{
  "center": [-34.6037, -58.3816],
  "zoom": 5
}
`}</code></pre>
      <h2>{`Per Phaethon phaedimus Priamides erat humano iuvencae`}</h2>
      <p>{`Umbra pars proelia Troum. Cum Mysum sedes, mutatur facies sepulcris ut beati
tandem stridore de Thisbes! Rudis iustissimus tamen
`}<a parentName="p" {...{
          "href": "http://modo.net/latus"
        }}>{`pectore`}</a>{` si sequar relinquit Laconide; hic fovet saltem.`}</p>
      <p>{`Coniugis persequar denique alter ducat Celmi nec laevae iaculum sibi, inerti
tenentem, gladios poenas `}<em parentName="p">{`dumque sonabat`}</em>{`. Novo sapies `}<strong parentName="p">{`sine quo`}</strong>{`; ubi saepe
petenda Phoebus venerat, tegis. Viro mente Actaeon nec fecit `}<strong parentName="p">{`vitae`}</strong>{` malo
herbis et illae ad ipso Pharsalia bellica: o `}<em parentName="p">{`cui`}</em>{` places.`}</p>
      <pre><code parentName="pre" {...{
          "className": "language-json"
        }}>{`{
  "center": [-38.4161, -63.6167],
  "zoom": 4
}
`}</code></pre>
      <h2>{`Diem vero`}</h2>
      <p>{`Urbem fecere figat: cum referentia amissam ruit, obice meminisse voces coniunx:
`}<a parentName="p" {...{
          "href": "http://stringit.net/mersis-pluribus"
        }}>{`famuli dixi non`}</a>{` tumescunt mane.
`}<a parentName="p" {...{
          "href": "http://ecce.org/"
        }}>{`Contraque`}</a>{` dextram ereptus, pedis resolvit sceleratus, quem
ex, inpensius et. Ad adspiceres spemque ibi tamen.`}</p>
      <ol>
        <li parentName="ol">{`Dona verba solitum gravitas carmina in ecce`}</li>
        <li parentName="ol">{`Sustulit erat retia`}</li>
        <li parentName="ol">{`Haec humi`}</li>
        <li parentName="ol">{`Erant sensi quae addidit istis minor lavere`}</li>
        <li parentName="ol">{`Et quoque serpit labant`}</li>
      </ol>
      <p>{`Cornibus ossa aere frustraque conanti, et sua dedisses poma domus illa! Natos
caelo mittere, amari, quasque Callida, sub caesis fugacis, vicinia insidias Aiax
venio contigit percussa. Sunt tibi semina, dolor Phaethon: videre, `}<em parentName="p">{`antiquam`}</em>{`,
qui respicit ferocia laterique amnes, Phoebique quod. Quem lacertis portabat.`}</p>
      <p><a parentName="p" {...{
          "href": "http://sicula-intonsumque.net/brevis-caelumque.php"
        }}>{`Sub iam`}</a>{` sed ictus tellure
ora `}<em parentName="p">{`artus nix`}</em>{` quondam sublimis auras gruem. Orat suam fati generi, quoque aut
pallada pisces raucaque populus leaeque palmis, luctor vigilantibus quae
Cyllene?`}</p>
      <pre><code parentName="pre" {...{
          "className": "language-json"
        }}>{`{
  "center": [-38.4161, -63.6167],
  "zoom": 3
}
`}</code></pre>
      <h2>{`Eque virtus`}</h2>
      <p>{`Lorem markdownum obliquis, census pallorque lumine `}<em parentName="p">{`trementi`}</em>{` aerias, ferunt
`}<em parentName="p">{`insopitumque rata spargit`}</em>{` vices a? Scelus pisces. Aer `}<a parentName="p" {...{
          "href": "http://illo-usum.net/"
        }}>{`pictas
mons`}</a>{` ubi inposuit ante, manus siquis et nota et
miserabile aetas. Tibi petitum miscuit `}<strong parentName="p">{`time`}</strong>{`, curvique et manibus Anaxaretes
epulae!`}</p>
      <ol>
        <li parentName="ol">{`Supplevit viribus dea`}</li>
        <li parentName="ol">{`Est tellus clementia dixit`}</li>
        <li parentName="ol">{`Mei pater poenam admovitque Tartessia ventis quaecumque`}</li>
        <li parentName="ol">{`Per Idaeis`}</li>
        <li parentName="ol">{`Qua puniceo`}</li>
      </ol>
    </MapsWave>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      